import { createAction, props } from '@ngrx/store';
export const actionPrefix = '[Invoice]';

/********************************
 *          LIST ACTIONS        *
 ********************************/
export const loadInvoices = createAction(actionPrefix + ' Load invoices');
export const loadInvoicesSuccess = createAction(actionPrefix + ' Load invoices success', props<{ data: any }>());
export const loadInvoicesFailure = createAction(actionPrefix + ' Load invoices failure', props<{ error: Error }>());

// @TODO: Remove when PHP has moved the stuff
export const getAmount = createAction(actionPrefix + ' Get amount');
export const getAmountSuccess = createAction(actionPrefix + ' Get amount Success', props<{ data: any }>());
export const getAmountFailure = createAction(actionPrefix + ' Get amount Failure', props<{ error: Error }>());
