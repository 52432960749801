import { createReducer, on, select } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
export const featureKey = 'invoice';
import * as InvoiceActions from './invoice.actions';

export const invoiceFeatureKey = 'invoice';

export interface Invoice {
  id: string;
  attributes: {
    invoiceDate: string;
    invoiceStamp: string;
    Pdf: string;
    priceGross: number;
    priceNet: number;
    priceVat: number;
    paid: boolean;
    status: string;
    unpaidAmount: number;
    startDate: string;
    stopDate: string;
    billingAddress: string;
    info: string;
  };
  links: {
    linkInvoicePdf: string;
    linkInvoiceReport: string;
  };
}

export interface State extends EntityState<Invoice> {
  loading: boolean;
  loaded: boolean;
  amount: number;
  amountLoaded: boolean;
  amountLoading: boolean;
}

export const adapter: EntityAdapter<Invoice> = createEntityAdapter<Invoice>({
  selectId: (invoice: any) => invoice.id,
  sortComparer: (invoiceA: any, invoiceB: any) => {
    const dateA = new Date(Date.parse(invoiceA.attributes.invoiceDate.split('.').reverse().join('/')));
    const dateB = new Date(Date.parse(invoiceB.attributes.invoiceDate.split('.').reverse().join('/')));

    return dateB.getTime() - dateA.getTime();
  }
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: false,
  amount: 0.0,
  amountLoaded: false,
  amountLoading: false
});

export const reducer = createReducer(
  initialState,
  on(InvoiceActions.loadInvoices, (state, action) => ({
    ...state,
    loading: true
  })),

  on(InvoiceActions.loadInvoicesSuccess, (state, action) => adapter.setAll(action.data, { ...state, loading: false, loaded: true })),

  on(InvoiceActions.loadInvoicesFailure, (state, action) => ({ ...state, loading: false, loaded: true, error: action.error })),

  on(InvoiceActions.getAmount, (state, action) => ({
    ...state,
    amountLoading: true,
    amountLoaded: false
  })),

  on(InvoiceActions.getAmountSuccess, (state, action) => ({
    ...state,
    amount: action.data.amount,
    amountLoading: false,
    amountLoaded: true
  })),

  on(InvoiceActions.getAmountFailure, (state, action) => ({
    ...state,
    amountLoading: false,
    amountLoaded: false
  }))
);

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectInvoiceds = selectIds;

// select the dictionary of user entities
export const selectInvoiceEntities = selectEntities;

// select the array of users
export const selectAllInvoices = selectAll;

// select the total user count
export const selectInvoiceTotal = selectTotal;
